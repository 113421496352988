/* eslint-disable array-callback-return */
/* eslint-disable no-loop-func */
import React, { useState } from 'react'
import {
	Popover,
	PopoverArrow,
	PopoverBody,
	PopoverCloseButton,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	Tag,
	Portal,
	Button,
	Box,
	HStack,
	Stack,
	VStack,
	Text,
	Spinner,
	useTheme,
	Center,
	Table,
	Tbody,
	Td,
	Th,
	Thead,
	Tr,
	Input,
} from '@chakra-ui/react'

import moment from 'moment'
import { getLocalAuthDetails } from './common.util'
import {
	getCurrentDateISTWidget,
	getCurrentDateISTWidgetDash,
	getCurrentDateUTCEOD,
	getCustomFormatttedDate,
	getEndDateOfMonth,
	getEndOfMonthISTWidgetDash,
	getStartOfMonthISTWidgetDash,
	getFormatDDMMYYYY,
	getSecondsToDays,
	getSecondsToHours,
	getStartDateOfMonth,
} from './date.utils'
import { FILTER_FLAG, FILTER_ID } from './filter.constant'
import { useDashboardContext } from '../context/dashboard.context'
import { useLanguageContext } from '../context/locale.context'

const isMobileView = localStorage.getItem('IS_MOBILE_VIEW')

export const processFilterOptions = (res, flag = 0, is_Sip_Widgets = false) => {
	const {
		APPLICATION,
		SUB_APPLICATION,
		CLUSTER,
		VERTICAL,
		GEOGRAPHY,
		CREATOR,
		TBCREATOR,
		SEGMENT,
		ACCOUNT,
		OPPURTUNITY,
		CATEGORY,
		FAMILY,
		PRODUCT,
		WORKFORCE_TAG,
		WORKFORCE,
		CAMPAIGN,
		KPI_CATEGORY,
		KPI_PRODUCT,
		KPI_TYPE,
		ASSET_TAG1,
		ASSET_TAG2,
		ASSET_TAG3,
		ASSET_TAG_TYPE1,
		ASSET_TAG_TYPE2,
		ASSET_TAG_TYPE3,
		CLASSIFY_ROLES,
		WORKFLOW,
		CUSTOMER,
		INDUSTRY,
	} = FILTER_FLAG
	let key = null
	let selectkey = null
	let optionList = [{ value: -1, label: 'NA' }]
	const resp = res.data.response
	if (resp.length) {
		switch (flag) {
			case APPLICATION:
				key = 'applicationList'
				selectkey = 'selectedApplication'
				optionList = resp.map(app => ({
					value: app.application_id,
					label: app.application_name,
					tag_label: app.application_label_name,
					tag_type_label: app.tag_type_label_name,
					isAppVisible: app.application_visibility_enabled,
					field_edit_enabled: app.field_edit_enabled,
				}))
				break
			case SUB_APPLICATION:
				key = 'subApplicationList'
				selectkey = 'selectedSubApplication'
				optionList = resp.map(app => ({
					value: app.tag_type_id,
					label:
						app.tag_type_id === 223
							? 'Target Planning'
							: app.tag_type_id === 224
							? 'Allocated Target'
							: app.tag_type_name,
					is_card_layout_enabled: !!app?.is_card_layout_enabled
						? app?.is_card_layout_enabled
						: 0,
					add_new_file_enabled: app.add_new_file_enabled,
				}))
				break
			case CLUSTER:
				key = 'clusterList'
				selectkey = 'selectedCluster'
				optionList = resp.map(app => ({
					value: app.tag_id,
					label: app.tag_name,
				}))
				break
			case VERTICAL:
				key = 'verticalList'
				selectkey = 'selectedVertical'
				optionList = resp.map(app => ({
					value: app.tag_id,
					label: app.tag_name,
				}))
				optionList.unshift({
					value: 0,
					label: 'All',
				})
				break
			case GEOGRAPHY:
				key = 'buildingList'
				selectkey = 'selectedBuilding'
				optionList = resp.map(app => ({
					value: app.account_id,
					label: app.account_name,
				}))
				break
			case CREATOR:
				key = 'creatorList'
				selectkey = 'selectedCreator'
				optionList = resp.map(app => ({
					value: app.asset_id,
					label: app.operating_asset_first_name,
					...app,
				}))
				break
			case TBCREATOR:
				key = 'creatorList'
				selectkey = 'selectedCreator'
				optionList = resp.map(app => ({
					value: app.asset_id,
					label: app.operating_asset_first_name,
					...app,
				}))
				optionList.unshift({ value: 0, label: 'All' })
				break
			case SEGMENT:
				key = 'segmentList'
				selectkey = 'selectedSegment'
				optionList = resp.map(app => ({
					value: app.tag_id,
					label: app.tag_name,
				}))
				break
			case CAMPAIGN:
				key = 'field5List'
				selectkey = 'selectedField5'
				optionList = resp.map(app => ({
					value: app.campaign_id,
					label: app.campaign_title,
				}))
				break
			case ACCOUNT:
				key = 'accountList'
				selectkey = 'selectedAccount'
				optionList = resp.map(app => ({
					value: app.activity_id,
					label: app.activity_title,
				}))
				optionList.splice(0, 0, {
					value: 0,
					label: 'All',
				})
				break
			case CATEGORY:
				key = 'categoryList'
				selectkey = 'selectedCategory'
				optionList = resp.map(app => ({
					value: app.tag_id,
					label: app.tag_name,
				}))
				break
			case FAMILY:
				key = 'familyList'
				selectkey = 'selectedFamily'
				optionList = resp.map(app => ({
					value: app.activity_type_id,
					label: app.activity_type_name,
				}))
				break
			case PRODUCT:
				key = 'productList'
				selectkey = 'selectedProduct'
				optionList = resp.map(app => ({
					value: app.activity_id,
					label: app.activity_title,
				}))
				optionList.splice(0, 0, {
					value: 0,
					label: 'All',
				})
				break
			case WORKFORCE_TAG:
				key = 'workforceTagList'
				selectkey = 'selectedWorkforceTag'
				optionList = resp.map(app => ({
					value: app.tag_id,
					label: app.tag_name,
				}))
				break
			case WORKFORCE:
				key = 'workforceList'
				selectkey = 'selectedWorkforce'
				optionList = resp.map(app => ({
					value: app.workforce_id,
					label: app.workforce_name,
				}))
				break
			case OPPURTUNITY:
				key = 'opportunityList'
				selectkey = 'selectedOpportunity'
				optionList = resp.map(app => ({
					value: app.activity_type_id,
					label: app.activity_type_name,
				}))
				break
			case KPI_PRODUCT:
				key = 'kpiProductList'
				selectkey = 'selectedKPIProduct'
				optionList = resp.map(app => ({
					value: app.lov_id,
					label: app.lov_name,
				}))
				optionList.unshift({
					value: 0,
					label: 'All',
				})
				break
			case KPI_CATEGORY:
				key = 'kpiCategoryList'
				selectkey = 'selectedKPICategory'
				optionList = resp
					.filter(item => [4, 5, 6, 7].includes(item.widget_type_category_id))
					.map(app => ({
						value: app.widget_type_category_id,
						label: app.widget_type_category_name,
						...app,
					}))
				optionList.unshift({
					value: 0,
					label: 'All',
				})
				break
			case KPI_TYPE:
				key = 'kpitypeList'
				selectkey = 'selectedKPIType'
				optionList = resp.map(app => ({
					value: app.widget_type_id,
					label: app.widget_type_name,
				}))
				optionList.unshift({
					value: 0,
					label: 'All',
				})
				break
			case ASSET_TAG1:
				key = 'tag1List'
				selectkey = 'selectedTag1'
				optionList = resp.map(app => ({
					value: app.tag_id,
					label: app.tag_name,
				}))
				break

			case ASSET_TAG2:
				key = 'tag2List'
				selectkey = 'selectedTag2'
				optionList = resp.map(app => ({
					value: app.tag_id,
					label: app.tag_name,
				}))
				break

			case ASSET_TAG3:
				key = 'tag3List'
				selectkey = 'selectedTag3'
				optionList = resp.map(app => ({
					value: app.tag_id,
					label: app.tag_name,
				}))
				break

			case ASSET_TAG_TYPE1:
				key = 'tagType1List'
				selectkey = 'selectedTagType1'
				optionList = resp.map(app => ({
					value: app.tag_type_id,
					label: app.tag_type_name,
				}))
				break
			case ASSET_TAG_TYPE2:
				key = 'tagType2List'
				selectkey = 'selectedTagType2'
				optionList = resp.map(app => ({
					value: app.tag_type_id,
					label: app.tag_type_name,
				}))
				break
			case ASSET_TAG_TYPE3:
				key = 'tagType3List'
				selectkey = 'selectedTagType3'
				optionList = resp.map(app => ({
					value: app.tag_type_id,
					label: app.tag_type_name,
				}))
				break
			case CLASSIFY_ROLES:
				key = 'roleList'
				selectkey = 'selectedRole'
				optionList = resp.map(app => ({
					value: app.asset_type_id,
					label: app.asset_type_name,
				}))
				optionList.unshift({
					value: 0,
					label: 'All',
				})
				break
			case WORKFLOW:
				key = 'workflowList'
				selectkey = 'selectedWorkflow'
				optionList = resp.map(it => ({
					value: it.activity_type_id,
					label: it.activity_type_name,
				}))
				break
			case INDUSTRY:
				key = 'industryList'
				selectkey = 'selectedIndustry'
				optionList = resp.map(it => ({
					value: it.industry_id,
					label: it.industry_name,
				}))
				break
			case CUSTOMER:
				key = 'customerList'
				selectkey = 'selectedCustomer'
				optionList = resp.map(it => ({
					value: it.operating_asset_id,
					label: it.operating_asset_first_name,
				}))
				break
			default:
				break
		}
	} else if (
		[TBCREATOR, CREATOR, CLUSTER, VERTICAL, CLASSIFY_ROLES].includes(flag) &&
		is_Sip_Widgets
	) {
		switch (flag) {
			case CREATOR:
				key = 'creatorList'
				selectkey = 'selectedCreator'
				break
			case TBCREATOR:
				key = 'creatorList'
				selectkey = 'selectedCreator'
				break
			case CLASSIFY_ROLES:
				key = 'roleList'
				selectkey = 'selectedRole'
				break
			case CLUSTER:
				key = 'clusterList'
				selectkey = 'selectedCluster'
				break
			case VERTICAL:
				key = 'verticalList'
				selectkey = 'selectedVertical'
				break
			default:
				break
		}
		optionList = [{ value: 0, label: 'All' }]
	} else if (
		[CREATOR, PRODUCT, ACCOUNT, WORKFORCE].includes(flag) &&
		!is_Sip_Widgets
	) {
		switch (flag) {
			case CREATOR:
				key = 'creatorList'
				selectkey = 'selectedCreator'
				break

			case PRODUCT:
				key = 'productList'
				selectkey = 'selectedProduct'
				break
			case ACCOUNT:
				key = 'accountList'
				selectkey = 'selectedAccount'
				break
			case WORKFORCE:
				key = 'workforceList'
				selectkey = 'selectedWorkforce'
				break
			default:
				break
		}
		optionList = [{ value: 0, label: 'All' }]
	}
	return { key, selectkey, optionList }
}

export const checkFieldDisabled = (
	isAccess,
	queryFilters,
	filterId = 0,
	authData
) => {
	const {
		selectedSubApplication,
		selectedCluster,
		selectedBuilding,
		selectedCategory,
		selectedFamily,
		selectedDateType,
		selectedTimescale,
		selectedStatus,
		selectedPeriodType,
		selectedWorkforceTag,
		selectedWorkforce,
		selectedTagType1,
		selectedTagType2,
		selectedTagType3,
		selectedVertical,
		selectedSipSegment,
	} = queryFilters
	const {
		CLUSTER,
		GEOGRAPHY,
		CREATOR,
		SEGMENT,
		WORKFORCE_TAG,
		WORKFORCE,
		CATEGORY,
		FAMILY,
		PRODUCT,
		ACCOUNT,
		DATE_TYPE,
		STATUS,
		SUB_STATUS,
		START_DATE,
		END_DATE,
		PERIOD,
		VERTICAL,
		ASSET_TAG1,
		ASSET_TAG2,
		ASSET_TAG3,
	} = FILTER_ID
	let isDisabled = false
	switch (filterId) {
		case SEGMENT:
		case WORKFORCE_TAG:
			// case CATEGORY:
			isDisabled = !isAccess
			break
		case WORKFORCE:
			// case CATEGORY:
			isDisabled = !isAccess
			break
		case CLUSTER:
			isDisabled =
				!isAccess ||
				(!!selectedSubApplication &&
					selectedSubApplication.value === 221 &&
					!!selectedWorkforceTag &&
					selectedWorkforceTag.value === 341) ||
				(!!selectedSubApplication &&
					(selectedSubApplication.value === 222 ||
						selectedSubApplication.value === 221) &&
					!!selectedSipSegment &&
					selectedSipSegment?.value === 358)
			break
		case GEOGRAPHY:
			isDisabled = !isAccess
			// ||
			// ([358].includes(selectedWorkforceTag?.value) &&
			//   [223, 224].includes(selectedSubApplication?.value));
			break
		case CREATOR:
			if (authData?.organization_flag_enable_resource_filter === 0) {
				if (
					(!isAccess || (!!selectedBuilding && selectedBuilding.value === 0)) &&
					![223, 224, 1931, 1932, 1933, 1934, 1935].includes(
						selectedSubApplication?.value
					)
				) {
					isDisabled = true
				}
			} else {
				isDisabled = !isAccess
			}
			break
		case FAMILY:
			if (!!selectedCategory && [-1, 0].includes(selectedCategory.value)) {
				isDisabled = true
			}
			break
		case PRODUCT:
			if (!selectedFamily || (!!selectedFamily && selectedFamily.value === 0)) {
				isDisabled = true
			}
			break
		case ACCOUNT:
			if (
				!!selectedSubApplication &&
				![111, 129].includes(selectedSubApplication.value)
			) {
				isDisabled = false
			}
			break
		case DATE_TYPE:
			if (!!selectedSubApplication && selectedSubApplication.value === 110) {
				isDisabled = true
			}
			break
		case STATUS:
			if (
				(!!selectedSubApplication && selectedSubApplication.value === 110) ||
				(!!selectedDateType && selectedDateType.value === 2)
			) {
				isDisabled = true
			}
			break
		case SUB_STATUS:
			if (!!selectedStatus && selectedStatus.value !== 1) {
				isDisabled = true
			}
			break
		case START_DATE:
		case END_DATE:
			if (!!selectedTimescale && selectedTimescale.value !== 0) {
				isDisabled = true
			}
			break
		case PERIOD:
			if (
				!!selectedPeriodType &&
				(selectedPeriodType.value === 8 || selectedPeriodType.value === 17)
			) {
				isDisabled = true
			}
			break
		case VERTICAL:
			if (
				!!selectedSubApplication &&
				[221, 223, 224].includes(selectedSubApplication.value) &&
				!!selectedWorkforceTag &&
				[184, 185].includes(selectedWorkforceTag.value)
			) {
				isDisabled = true
			} else if (
				!!selectedSubApplication &&
				[221, 222].includes(selectedSubApplication.value) &&
				!!selectedSipSegment &&
				[184, 185].includes(selectedSipSegment?.value)
			) {
				isDisabled = true
			}

			break
		case ASSET_TAG1:
			if (!!selectedTagType1 && selectedTagType1.value === 0) {
				isDisabled = true
			}
			break
		case ASSET_TAG2:
			if (!!selectedTagType2 && selectedTagType2.value === 0) {
				isDisabled = true
			}
			break
		case ASSET_TAG3:
			if (!!selectedTagType3 && selectedTagType3.value === 0) {
				isDisabled = true
			}
			break
		default:
			return false
	}
	return isDisabled
}

//To do: Remove unused params
export const createWidgetParams = (
	singleWidget,
	widgetInline,
	queryFilters
) => {
	widgetInline.clusterTagIdSelected = !!queryFilters.selectedCluster
		? queryFilters.selectedCluster.value
		: 0
	widgetInline.accountIdSelected = !!queryFilters.selectedBuilding
		? queryFilters.selectedBuilding.value
		: 0
	widgetInline.creatorIdSelected = !!queryFilters.selectedCreator
		? queryFilters.selectedCreator.value
		: 0
	widgetInline.productCategoryIdSelected = !!queryFilters.selectedCategory
		? queryFilters.selectedCategory.value
		: 0
	widgetInline.productFamilyIdSelected = !!queryFilters.selectedFamily
		? queryFilters.selectedFamily.value
		: 0
	widgetInline.productIdSelected = !!queryFilters.selectedProduct
		? queryFilters.selectedProduct.value
		: 0
	widgetInline.workflowIdSelected = !!queryFilters.selectedWorkflow
		? queryFilters.selectedWorkflow.value
		: 0
	widgetInline.industryIdSelected = !!queryFilters.selectedIndustry
		? queryFilters.selectedIndustry.value
		: 0
	widgetInline.customerIdSelected = !!queryFilters.selectedCustomer
		? queryFilters.selectedCustomer.value
		: 0
	// widgetInline.campaignIdSelected = !!queryFilters.selectedCampaign
	//   ? queryFilters.selectedCampaign.value
	//   : 0;
	widgetInline.segmentIdSelected =
		queryFilters.selectedSubApplication.value !== 130
			? !!queryFilters.selectedSegment
				? queryFilters.selectedSegment.value
				: 0
			: 0
	widgetInline.accountActivityIdSelected = !!queryFilters.selectedAccount
		? queryFilters.selectedAccount.value
		: 0
	widgetInline.opportunityIdSelected = !!queryFilters.selectedOpportunity
		? queryFilters.selectedOpportunity.value
		: 0
	widgetInline.workforceIdSelected = !!queryFilters.selectedWorkforceTag
		? queryFilters.selectedWorkforceTag.value
		: 0
	widgetInline.statusIdSelected = !!queryFilters.selectedStatus
		? queryFilters.selectedStatus.value
		: 0
	widgetInline.subStatusIdSelected = !!queryFilters.selectedSubstatus
		? queryFilters.selectedSubstatus.value
		: 0
	widgetInline.widgetIdSelected = !!queryFilters.selectedWidget
		? queryFilters.selectedWidget.value
		: 0
	widgetInline.timescaleIdSelected = !!queryFilters.selectedTimescale
		? queryFilters.selectedTimescale.value
		: 0
	widgetInline.startDateTimeSelected = queryFilters.startDateTime
	widgetInline.endDateTimeSelected = queryFilters.endDateTime
	widgetInline.dateTypeIdSelected = !!queryFilters.selectedDateType
		? queryFilters.selectedDateType.value
		: 0
	widgetInline.selectedSubApplication = !!queryFilters.selectedSubApplication
		? queryFilters.selectedSubApplication.value
		: 0
	widgetInline.selectedField1 = !!queryFilters.selectedField1
		? queryFilters.selectedField1.value
		: 0
	widgetInline.selectedField2 = !!queryFilters.selectedField2
		? queryFilters.selectedField2.value
		: 0
	widgetInline.selectedField3 = !!queryFilters.selectedField3
		? queryFilters.selectedField3.value
		: 0
	widgetInline.selectedField4 = !!queryFilters.selectedField4
		? queryFilters.selectedField4.value
		: 0
	widgetInline.selectedField5 = !!queryFilters.selectedField5
		? queryFilters.selectedField5.value
		: 0
	widgetInline.selectedTag1 = !!queryFilters.selectedTag1
		? queryFilters.selectedTag1.value
		: 0
	widgetInline.selectedTag2 = !!queryFilters.selectedTag2
		? queryFilters.selectedTag2.value
		: 0
	widgetInline.selectedTag3 = !!queryFilters.selectedTag3
		? queryFilters.selectedTag3.value
		: 0

	widgetInline.selectedTagType1 = !!queryFilters.selectedTagType1
		? queryFilters.selectedTagType1.value
		: 0
	widgetInline.selectedTagType2 = !!queryFilters.selectedTagType2
		? queryFilters.selectedTagType2.value
		: 0
	widgetInline.selectedTagType3 = !!queryFilters.selectedTagType3
		? queryFilters.selectedTagType3.value
		: 0

	let idAsset = 0
	let idManagerAsset = 0
	let idDirectReport = widgetInline.filter_is_direct_report || 0
	let isDatetimeConsidered = 1

	if (widgetInline.filter_is_direct_report >= 1) {
		idManagerAsset = widgetInline.creatorIdSelected || -1
		// idDirectReport = widgetInline.filter_is_direct_report;
	} else {
		idAsset = widgetInline.creatorIdSelected || 0
	}

	if (widgetInline.filter_is_datetime_considered === 0) {
		isDatetimeConsidered = widgetInline.filter_is_datetime_considered
	}

	const tag_type_id = JSON.parse(widgetInline.filter_tag_type_id)
	const tagTypeValue = tag_type_id[0].tag_type_id
	const userData = getLocalAuthDetails()
	const { asset_id } = userData
	const filter_organization_id = queryFilters.selectedOrganization?.value
	return {
		...userData,
		filter_organization_id:
			filter_organization_id || filter_organization_id === 0
				? filter_organization_id
				: -1,
		widget_type_id: singleWidget.widget_type_id,
		widget_activity_id: singleWidget.activity_id,

		//Filter Params
		filter_date_type_id: queryFilters.selectedDateType
			? queryFilters.selectedDateType.value
			: 0,
		filter_timeline_id:
			widgetInline.filter_timeline_id === -1
				? 6
				: widgetInline.filter_timeline_id,
		filter_account_id: widgetInline.filter_account_id || 0,
		filter_workforce_type_id: widgetInline.filter_workforce_type_id,
		filter_workforce_id: widgetInline.filter_workforce_id,
		filter_asset_id:
			idAsset === 0 &&
			[148, 174, 179].includes(widgetInline.selectedSubApplication)
				? asset_id
				: idAsset,
		tag_type_id: tagTypeValue,
		filter_tag_type_id:
			typeof widgetInline.filter_tag_type_id === 'string'
				? widgetInline.filter_tag_type_id
				: JSON.stringify(widgetInline.filter_tag_type_id),
		filter_tag_id: widgetInline.filter_tag_id,
		filter_activity_type_id: widgetInline.opportunityIdSelected || 0,
		filter_activity_status_type_id: widgetInline.statusIdSelected
			? widgetInline.statusIdSelected
			: 0,
		filter_activity_status_tag_id: widgetInline.subStatusIdSelected
			? widgetInline.subStatusIdSelected
			: 0,
		activity_status_id: widgetInline.activity_status_id,
		filter_activity_status_id: 0,
		filter_form_id: widgetInline.filter_form_id,
		filter_field_id: widgetInline.filter_field_id,
		datetime_start:
			widgetInline.selectedSubApplication !== 148
				? getCurrentDateISTWidget(widgetInline.startDateTimeSelected)
				: null,
		datetime_end:
			widgetInline.selectedSubApplication !== 148
				? getCurrentDateUTCEOD(widgetInline.endDateTimeSelected)
				: null,
		filter_asset_tag_type_1: widgetInline.selectedTagType1
			? widgetInline.selectedTagType1
			: 0,
		filter_asset_tag_type_2: widgetInline.selectedTagType2
			? widgetInline.selectedTagType2
			: 0,
		filter_asset_tag_type_3: widgetInline.selectedTagType3
			? widgetInline.selectedTagType3
			: 0,

		filter_asset_tag_1: widgetInline.selectedTag1
			? widgetInline.selectedTag1
			: 0,
		filter_asset_tag_2: widgetInline.selectedTag2
			? widgetInline.selectedTag2
			: 0,
		filter_asset_tag_3: widgetInline.selectedTag3
			? widgetInline.selectedTag3
			: 0,
		filter_arp_workflow_type_id: widgetInline.workflowIdSelected,
		filter_industry_id: widgetInline.industryIdSelected,
		filter_cusomter_asset_id: widgetInline.customerIdSelected,
		filter_hierarchy: widgetInline.filter_hierarchy
			? widgetInline.filter_hierarchy
			: 0,
		filter_segment_id: widgetInline.segmentIdSelected
			? widgetInline.segmentIdSelected
			: 0,
		workforce_tag_id: widgetInline.workforceIdSelected
			? widgetInline.workforceIdSelected
			: 0,
		filter_circle_id: widgetInline.accountIdSelected
			? widgetInline.accountIdSelected
			: 0,
		filter_reporting_manager_id: idManagerAsset,
		filter_product_category_id: widgetInline.productCategoryIdSelected
			? widgetInline.productCategoryIdSelected
			: 0,
		filter_product_family_id: widgetInline.productFamilyIdSelected
			? widgetInline.productFamilyIdSelected
			: 0,
		filter_product_activity_id: widgetInline.productIdSelected
			? widgetInline.productIdSelected
			: 0,
		filter_account_activity_id: widgetInline.accountActivityIdSelected
			? widgetInline.accountActivityIdSelected
			: 0,
		filter_is_value_considered: widgetInline.filter_is_value_considered || 0,
		filter_cluster_tag_id: widgetInline.clusterTagIdSelected
			? widgetInline.clusterTagIdSelected
			: 0,
		// filter_campaign_activity_id: widgetInline.campaignIdSelected
		//   ? widgetInline.campaignIdSelected
		//   : 0,
		filter_timescale: widgetInline.timescaleIdSelected
			? widgetInline.timescaleIdSelected
			: null,
		filter_field_entity_1: widgetInline.selectedField1
			? widgetInline.selectedField1
			: null,
		filter_field_entity_2: widgetInline.selectedField2
			? widgetInline.selectedField2
			: null,
		filter_field_entity_3: widgetInline.selectedField3
			? widgetInline.selectedField3
			: null,
		filter_field_entity_4: widgetInline.selectedField4
			? widgetInline.selectedField4
			: null,
		filter_field_entity_5: widgetInline.selectedField5
			? widgetInline.selectedField5
			: null,
		filter_is_direct_report: idDirectReport,
		filter_is_lead: widgetInline.filter_is_lead || 0,
		filter_is_datetime_considered: isDatetimeConsidered,
		filter_asset_type_id: widgetInline.filter_asset_type_id
			? widgetInline.filter_asset_type_id
			: 0,
		page_start: 0,
		page_limit: 20,
	}
}

// export const changeWidgetDataOnQueryFilters = (initialData, queryFilters) => {
// 	const userData = getLocalAuthDetails()

// 	const selectedSubApplication = !!queryFilters.selectedSubApplication
// 		? queryFilters.selectedSubApplication.value
// 		: 0

// 	return {
// 		...userData,
// 		...initialData,
// 		//Filter Params
// 		filter_organization_id: queryFilters.selectedOrganization?.value ?? -1,
// 		filter_date_type_id: queryFilters.selectedDateType
// 			? queryFilters.selectedDateType.value
// 			: 0,
// 		filter_activity_type_id: !!queryFilters.selectedOpportunity
// 			? queryFilters.selectedOpportunity.value
// 			: 0,
// 		filter_activity_status_type_id: !!queryFilters.selectedStatus
// 			? queryFilters.selectedStatus.value
// 			: 0,
// 		filter_activity_status_tag_id: !!queryFilters.selectedSubstatus
// 			? queryFilters.selectedSubstatus.value
// 			: 0,
// 		datetime_start:
// 			selectedSubApplication !== 148
// 				? getCurrentDateISTWidget(queryFilters.startDateTime)
// 				: null,
// 		datetime_end:
// 			selectedSubApplication !== 148
// 				? getCurrentDateUTCEOD(queryFilters.endDateTime)
// 				: null,
// 		filter_asset_tag_type_1: !!queryFilters.selectedTagType1
// 			? queryFilters.selectedTagType1.value
// 			: 0,
// 		filter_asset_tag_type_2: !!queryFilters.selectedTagType2
// 			? queryFilters.selectedTagType2.value
// 			: 0,
// 		filter_asset_tag_type_3: !!queryFilters.selectedTagType3
// 			? queryFilters.selectedTagType3.value
// 			: 0,

// 		filter_asset_tag_1: !!queryFilters.selectedTag1
// 			? queryFilters.selectedTag1.value
// 			: 0,
// 		filter_asset_tag_2: !!queryFilters.selectedTag2
// 			? queryFilters.selectedTag2.value
// 			: 0,
// 		filter_asset_tag_3: !!queryFilters.selectedTag3
// 			? queryFilters.selectedTag3.value
// 			: 0,
// 		filter_arp_workflow_type_id: !!queryFilters.selectedWorkflow
// 			? queryFilters.selectedWorkflow.value
// 			: 0,
// 		filter_industry_id: !!queryFilters.selectedIndustry
// 			? queryFilters.selectedIndustry.value
// 			: 0,
// 		filter_cusomter_asset_id: !!queryFilters.selectedCustomer
// 			? queryFilters.selectedCustomer.value
// 			: 0,
// 		filter_segment_id:
// 			queryFilters.selectedSubApplication.value !== 130
// 				? !!queryFilters.selectedSegment
// 					? queryFilters.selectedSegment.value
// 					: 0
// 				: 0,
// 		workforce_tag_id: !!queryFilters.selectedWorkflow
// 			? queryFilters.selectedWorkflow.value
// 			: 0,
// 		filter_circle_id: !!queryFilters.selectedBuilding
// 			? queryFilters.selectedBuilding.value
// 			: 0,
// 		filter_product_category_id: !!queryFilters.selectedCategory
// 			? queryFilters.selectedCategory.value
// 			: 0,
// 		filter_product_family_id: !!queryFilters.selectedFamily
// 			? queryFilters.selectedFamily.value
// 			: 0,
// 		filter_product_activity_id: !!queryFilters.selectedProduct
// 			? queryFilters.selectedProduct.value
// 			: 0,
// 		filter_account_activity_id: !!queryFilters.selectedAccount
// 			? queryFilters.selectedAccount.value
// 			: 0,
// 		filter_cluster_tag_id: !!queryFilters.selectedCluster
// 			? queryFilters.selectedCluster.value
// 			: 0,
// 		filter_timescale: !!queryFilters.selectedTimescale
// 			? queryFilters.selectedTimescale.value
// 			: null,
// 		filter_field_entity_1: !!queryFilters.selectedField1
// 			? queryFilters.selectedField1.value
// 			: null,
// 		filter_field_entity_2: !!queryFilters.selectedField2
// 			? queryFilters.selectedField2.value
// 			: null,
// 		filter_field_entity_3: !!queryFilters.selectedField3
// 			? queryFilters.selectedField3.value
// 			: null,
// 		filter_field_entity_4: !!queryFilters.selectedField4
// 			? queryFilters.selectedField4.value
// 			: null,
// 		filter_field_entity_5: !!queryFilters.selectedField5
// 			? queryFilters.selectedField5.value
// 			: null,
// 		page_start: 0,
// 		page_limit: 20,
// 	}
// }

export const processVerticalBarChartData = (
	widget_type_id,
	filter_timeline_id,
	payload,
	response
) => {
	let data = []
	const dataSource = {}
	let temp = {}
	let totalValue = 0

	let yAxisName = ''
	let xAxisName_1 = ''
	if (widget_type_id === 28) {
		yAxisName = 'VOLUME'
	} else if (widget_type_id === 29) {
		yAxisName = 'VALUE'
	} else if (
		widget_type_id === 25 ||
		widget_type_id === 26 ||
		widget_type_id === 27
	) {
		yAxisName = 'HOURS'
	} else if (widget_type_id === 66) {
		yAxisName = 'Average'
	} else if (widget_type_id === 67) {
		yAxisName = 'Volume'
	} else if (widget_type_id === 202) {
		yAxisName = 'OTC'
	} else if (widget_type_id === 203) {
		yAxisName = 'Annuity'
	}

	dataSource.chart = {
		plottooltext: yAxisName + ' on <b>$label</b> is <b>$value</b>',
		bgColor: '#ffffff',
		valueFontColor: '#718096',
		yAxisNameFontColor: '#000',
		showvalues: '1',
		labelFontColor: '#000',
		theme: 'fusion',
		xAxisName: xAxisName_1,
		yAxisName: yAxisName,
		labeldisplay: 'ROTATE',
		slantLabel: '1',
		legendNumColumns: isMobileView ? '2' : 0,
	}

	if (!!response) {
		response.shift()
	}
	let found = false

	let request_start_datetime = payload.datetime_start
	let request_end_datetime = payload.datetime_end

	for (let iteratorI = 0; iteratorI < response.length; iteratorI++) {
		response[iteratorI].result.map(row => {
			let temp = {}
			let jsonParams1 = Object.assign({}, payload)
			totalValue += row.value
			jsonParams1.datetime_start =
				getCurrentDateISTWidgetDash(row['x-axis']) + ' 00:00:00'
			jsonParams1.datetime_end =
				getCurrentDateISTWidgetDash(row['x-axis']) + ' 23:59:59'

			if (
				widget_type_id === 28 ||
				widget_type_id === 29 ||
				widget_type_id === 66 ||
				widget_type_id === 67
			) {
				if (filter_timeline_id === 13) {
					row['x-axis']
						? (temp.label = row['x-axis'].substring(0, 7))
						: (temp.label = row.date.substring(0, 7))

					jsonParams1.datetime_start = getStartDateOfMonth(row['x-axis'])
					jsonParams1.datetime_end = getEndDateOfMonth(row['x-axis'])
				} else {
					row['x-axis']
						? (temp.label = row['x-axis'].substring(0, 10))
						: (temp.label = row.date.substring(0, 10))
				}
				temp.value = row.value
				temp.linkData = jsonParams1
			} else if (widget_type_id === 25) {
				temp.label = row.activity_status_name
				temp.value = getSecondsToHours(row.value)
				jsonParams1.filter_activity_status_id = row.activity_status_id
			} else if (widget_type_id === 26) {
				temp.label = row.activity_status_tag_name
				temp.value = getSecondsToHours(row.value)
				jsonParams1.filter_activity_status_tag_id = row.activity_status_tag_id
			} else if (widget_type_id === 27) {
				temp.label = row.activity_status_type_name
				temp.value = getSecondsToHours(row.value)
				jsonParams1.filter_activity_status_type_id = row.activity_status_type_id
			} else if (widget_type_id === 202 || widget_type_id === 203) {
				temp.label = row.field_value
				temp.value = row.value
				jsonParams1.filter_mapping_combo_value = row.field_value
				jsonParams1.datetime_start = request_start_datetime
				jsonParams1.datetime_end = request_end_datetime
				temp.linkData = jsonParams1
			}

			found = false
			if (widget_type_id === 28 || widget_type_id === 29) {
				for (var i = 0; i < data.length; i++) {
					if (data[i].label === temp.label) {
						data[i].value = data[i].value + temp.value
						found = true
						break
					}
				}
			}

			if (found === false) {
				data.push(temp)
			}
		})
	}

	if (widget_type_id === 28 || widget_type_id === 29) {
		data.sort((a, b) => (a.label > b.label ? 1 : -1))
	}
	if (isMobileView) {
		dataSource.categories = []
		dataSource.categories.push({
			category: data,
		})
		dataSource.dataset = []
		dataSource.dataset.push({
			data: data,
		})
	} else {
		dataSource.data = data
	}
	return dataSource
}

export const processStackedChartData = (
	widget_type_id,
	filter_timeline_id,
	payload,
	response,
	queryFilters
) => {
	const dataSource = {}
	let temp = {}
	let totalValue = 0

	let distributionType = ''
	if (widget_type_id === 34) {
		distributionType = 'Volume'
	} else if (widget_type_id === 35) {
		distributionType = 'Value'
	} else if (widget_type_id === 39) {
		distributionType = 'Volume'
	} else if (widget_type_id === 40) {
		distributionType = 'Value'
	} else if (widget_type_id === 41) {
		distributionType = 'Volume'
	} else if (widget_type_id === 42) {
		distributionType = 'Value'
	} else if (widget_type_id === 61) {
		distributionType = 'Volume'
	}

	let flag = 0
	let xAxisName
	let yAxisName2

	if (
		Number(filter_timeline_id) === 7 ||
		Number(filter_timeline_id) === 8 ||
		Number(filter_timeline_id) === 13
	) {
		flag = 1
		xAxisName = 'MONTHS'
	}

	if (widget_type_id === 39 || widget_type_id === 40) {
		xAxisName = 'Reference'
		yAxisName2 = 'Status'
	} else if (widget_type_id === 41 || widget_type_id === 42) {
		xAxisName = 'Options'
		yAxisName2 = 'Status'
	}
	dataSource.chart = {
		bgColor: '#ffffff',
		valueFontColor: '#718096',
		showsum: '1',
		plottooltext:
			distributionType +
			'of <b>$seriesName</b> on <b>$label</b> is <b>$dataValue</b>',
		// plottooltext:
		//   'Cumulated ' +
		//   distributionType +
		//   'of <b>$seriesName</b> on <b>$label</b> is <b>$dataValue</b>',
		showToolTip: 1,
		toolTipBgColor: '#EEFFE5',
		drawcrossline: '1',
		labelFontColor: '#000',
		xAxisName: xAxisName,
		yAxisName: yAxisName2,
		labeldisplay: 'ROTATE',
		slantLabel: '1',
		theme: 'fusion',
		lineThickness: isMobileView ? '3' : 0,
		showHoverEffect: '0',
		scrollheight: isMobileView ? '10' : 0,
		flatScrollBars: isMobileView ? '1' : 0,
		legendNumColumns: isMobileView ? '2' : 0,
		formatNumberScale: '1',
		numberScaleValue:
			queryFilters?.displayNumbersIn?.value === 'Crores'
				? '1000,100,100'
				: '1000,1000,1000',
		//Customizing number scale units
		numberScaleUnit:
			queryFilters?.displayNumbersIn?.value === 'Crores' ? 'K,L,Cr' : 'K,M,B',
	}

	dataSource.categories = []
	dataSource.categories.push('category')

	let tempDates = []
	let tempStatuses = []
	if (widget_type_id === 39 || widget_type_id === 40) {
		response.map(row => {
			tempDates.push(row.mapping_activity_title)

			tempStatuses.push(row.activity_status_type_name)
		})
	} else if (widget_type_id === 41 || widget_type_id === 42) {
		response.map(row => {
			tempDates.push(row.data_type_combo_name)

			tempStatuses.push(row.activity_status_type_name)
		})
	} else if (
		widget_type_id === 61 ||
		widget_type_id === 34 ||
		widget_type_id === 35
	) {
		if (!!response) {
			response.shift()
		}
		for (let iteratorI = 0; iteratorI < response.length; iteratorI++) {
			response[iteratorI].result.map(row => {
				flag === 1
					? tempDates.push(row['x-axis'])
					: tempDates.push(getFormatDDMMYYYY(row['x-axis']))
				tempStatuses.push(row.activity_status_name)
			})
		}
	} else {
		if (!!response) {
			response.shift()
		}
		for (let iteratorI = 0; iteratorI < response.length; iteratorI++) {
			response[iteratorI].result.map(row => {
				flag === 1
					? tempDates.push(row['x-axis'])
					: tempDates.push(getFormatDDMMYYYY(row['x-axis']))
				tempStatuses.push(row.activity_status_type_name)
			})
		}
	}
	if (!tempDates.length > 0 || !tempStatuses.length > 0) {
		return 1
	}

	let newTempDates = [...new Set(tempDates)]
	let newTempStatuses = [...new Set(tempStatuses)]

	let category = []
	if (
		widget_type_id === 39 ||
		widget_type_id === 40 ||
		widget_type_id === 41 ||
		widget_type_id === 42
	) {
		newTempDates.map(row => {
			category.push({ label: row })
		})
	} else {
		newTempDates.map(row => {
			category.push({ label: row.substring(0, 10) })
		})
	}
	dataSource.categories = [{ category: category }]

	let statusObj
	let dataset = []
	if (
		widget_type_id === 39 ||
		widget_type_id === 40 ||
		widget_type_id === 41 ||
		widget_type_id === 42
	) {
		newTempStatuses.map(status => {
			statusObj = {}
			statusObj.data = []
			statusObj.seriesname = status
			temp = []

			response.map(row => {
				let jsonParams2 = Object.assign({}, payload)
				if (row.activity_status_type_name === status) {
					let statusTypes = []

					let filter_status_type = {
						activity_status_type_id: row.activity_status_type_id,
					}
					statusTypes.push(filter_status_type)
					jsonParams2.filter_activity_status_type_id =
						JSON.stringify(statusTypes)
					if (widget_type_id === 39 || widget_type_id === 40) {
						temp.push({
							category: row.mapping_activity_title,
							value: row.value,
							linkData: jsonParams2,
						})
					} else {
						temp.push({
							category: row.data_type_combo_name,
							value: row.value,
							linkData: jsonParams2,
						})
					}
				}
			})

			let found = false
			for (let i = 0; i < newTempDates.length; i++) {
				found = false
				for (let j = 0; j < temp.length; j++) {
					if (newTempDates[i] === temp[j].category) {
						statusObj.data.push({
							category: newTempDates[i],
							value: temp[j].value,
							linkData: temp[j].linkData,
						})
						found = true
						break
					}
				}
				if (found === false) {
					statusObj.data.push({ category: newTempDates[i], value: 0 })
				}
			}
			dataset.push(statusObj)
		})
	} else {
		newTempStatuses.map(status => {
			statusObj = {}
			statusObj.data = []
			statusObj.seriesname = status
			temp = []

			for (let iteratorI = 0; iteratorI < response.length; iteratorI++) {
				response[iteratorI].result.map(row => {
					let jsonParams2 = Object.assign({}, payload)
					if (
						widget_type_id === 61 ||
						widget_type_id === 34 ||
						widget_type_id === 35
					) {
						if (row.activity_status_name === status) {
							let statuIds = []
							if (flag === 1) {
								jsonParams2.datetime_start =
									getStartOfMonthISTWidgetDash(row['x-axis']) + ' 00:00:00'
								jsonParams2.datetime_end =
									getEndOfMonthISTWidgetDash(row['x-axis']) + ' 23:59:59'
							} else {
								jsonParams2.datetime_start =
									getCurrentDateISTWidgetDash(row['x-axis']) + ' 00:00:00'
								jsonParams2.datetime_end =
									getCurrentDateISTWidgetDash(row['x-axis']) + ' 23:59:59'
							}

							totalValue = totalValue + row.value
							let filter_status = {
								activity_status_id: row.activity_status_id,
							}
							statuIds.push(filter_status)
							jsonParams2.activity_status_id = row.activity_status_id
							jsonParams2.filter_activity_status_id = row.activity_status_id
							flag === 1
								? temp.push({
										category: row['x-axis'],
										value: row.value,
										linkData: jsonParams2,
								  })
								: temp.push({
										category: getFormatDDMMYYYY(row['x-axis']),
										value: row.value,
										linkData: jsonParams2,
								  })
						}
					} else if (row.activity_status_type_name === status) {
						let statusTypes = []
						jsonParams2.datetime_start =
							getCurrentDateISTWidgetDash(row['x-axis']) + ' 00:00:00'
						jsonParams2.datetime_end =
							getCurrentDateISTWidgetDash(row['x-axis']) + ' 23:59:59'

						let filter_status_type = {
							activity_status_type_id: row.activity_status_type_id,
						}
						statusTypes.push(filter_status_type)
						jsonParams2.filter_activity_status_type_id =
							JSON.stringify(statusTypes)

						flag === 1
							? temp.push({
									category: row['x-axis'],
									value: row.value,
									linkData: jsonParams2,
							  })
							: temp.push({
									category: getFormatDDMMYYYY(row['x-axis']),
									value: row.value,
									linkData: jsonParams2,
							  })
					}
				})
			}

			let found = false
			for (let i = 0; i < newTempDates.length; i++) {
				found = false
				for (let j = 0; j < temp.length; j++) {
					if (newTempDates[i] === temp[j].category) {
						statusObj.data.push({
							category: newTempDates[i].substring(0, 10),
							value: temp[j].value,
							linkData: temp[j].linkData,
						})
						found = true
						break
					}
				}
				if (found === false) {
					statusObj.data.push({
						category: newTempDates[i].substring(0, 5),
						value: 0,
					})
				}
			}
			dataset.push(statusObj)
		})
	}
	dataSource.dataset = dataset

	return dataSource
}

export const processPieChartData = (
	widget_type_id,
	payload,
	response,
	queryFilters,
	flag
) => {
	let data = []
	let dataSource = {}
	let temp = {}
	let totalValue = 0
	let type

	const volumeList = [23, 37, 45, 53, 62, 63, 65, 39, 41]
	const valueList = [24, 38, 46, 54, 40, 42, 202, 203]
	if (volumeList.includes(widget_type_id)) {
		type = 'Volume'
	} else if (valueList.includes(widget_type_id)) {
		type = 'Value'
	}

	dataSource.chart = {
		caption: 'Total ' + type + ':',
		captionFontColor: '#718096',
		plottooltext: 'Cumulated ' + type + ' <b>$label</b> is <b>$value</b>',
		bgColor: '#FFFFFF',
		showvalues: isMobileView ? '0' : '1',
		showPercentValues: '0',
		labelFontColor: '#000',
		usedataplotcolorforlabels: '1',
		theme: 'fusion',
		smartLineColor: '#009B76',
		smartLineThickness: '1',
		showLegend: isMobileView ? '1' : 0,
		widgetValueType: type,
		enableSmartLabels: isMobileView ? '0' : 1,
		showLabels: isMobileView ? '0' : 1,
		showPercentageInLabel: isMobileView ? '0' : 1,
		legendNumColumns: isMobileView ? 2 : 0,
		legendItemFontSize: isMobileView ? 14 : 0,
	}

	if (!!response) {
		response.shift()
	}
	for (let iteratorI = 0; iteratorI < response.length; iteratorI++) {
		response[iteratorI].result.map(row => {
			let jsonParams5 = Object.assign({}, payload)
			temp = {}
			let statusTypes = []
			let statuses = []

			totalValue += row.value
			if (widget_type_id === 23 || widget_type_id === 24) {
				temp.label = row.activity_status_type_name
				temp.value = row.value
				let filter_status_type = {
					activity_status_type_id: row.activity_status_type_id,
				}
				statusTypes.push(filter_status_type)
				jsonParams5.filter_activity_status_type_id = !flag
					? JSON.stringify(statusTypes)
					: 0
				temp.linkData = jsonParams5
			} else if (
				widget_type_id === 37 ||
				widget_type_id === 38 ||
				widget_type_id === 53 ||
				widget_type_id === 54 ||
				widget_type_id === 45 ||
				widget_type_id === 46
			) {
				if (row.hasOwnProperty('activity_status_tag_name')) {
					temp.label = row.activity_status_tag_name
					temp.value = row.value || 0
					let filter_status = {
						activity_status_tag_id: row.activity_status_tag_id,
					}
					statuses.push(filter_status)
					jsonParams5.filter_activity_status_tag_id = !flag
						? row.activity_status_tag_id
						: [45, 46].includes(widget_type_id)
						? 6
						: 0
					temp.linkData = jsonParams5
					temp.linkData = jsonParams5
				} else {
					temp.label = row.activity_status_name
					temp.value = row.value
					let filter_status = {
						activity_status_id: row.activity_status_id,
					}
					statuses.push(filter_status)
					jsonParams5.filter_activity_status_id = !flag
						? row.activity_status_id
						: 0
					jsonParams5.activity_status_id =
						'[{"activity_status_id":' + row.activity_status_id + '}]'
					temp.linkData = jsonParams5
				}
			} else if (widget_type_id === 39 || widget_type_id === 40) {
				temp.label = row.mapping_activity_title
				temp.value = row.value
				jsonParams5.filter_mapping_activity_id = !flag
					? row.mapping_activity_id
					: 0
				temp.linkData = jsonParams5
			} else if (
				widget_type_id === 41 ||
				widget_type_id === 42 ||
				widget_type_id === 202 ||
				widget_type_id === 203
			) {
				temp.label = row.field_value
				temp.value = row.value
				jsonParams5.filter_mapping_combo_value = !flag ? row.field_value : 0
				temp.linkData = jsonParams5
			} else if (widget_type_id === 65) {
				temp.label = row.product_tag_name
				temp.value = row.value
				jsonParams5.filter_product_category_id = !flag ? row.product_tag_id : 0

				temp.linkData = jsonParams5
			} else {
				temp.label = row.activity_status_name
				temp.value = row.value
				let filter_status = {
					activity_status_id: row.activity_status_id,
				}
				statuses.push(filter_status)
				jsonParams5.filter_activity_status_id = row.activity_status_id
				jsonParams5.activity_status_id =
					'[{"activity_status_id":' + row.activity_status_id + '}]'
				temp.linkData = jsonParams5
			}

			data.push(temp)
		})
	}

	// if (
	//   widget_type_id === 38 ||
	//   widget_type_id === 54 ||
	//   widget_type_id === 24 ||
	//   widget_type_id === 46
	// ) {
	//   totalValue = getValueInThousands(totalValue);
	// }

	dataSource.chart.caption = `Total ${type} : ${
		getTotalValueFormatted(totalValue)
		// totalValue > Math.pow(10, 6)
		//   ? getTotalValueFormatted(totalValue)
		//   : totalValue
	}`
	dataSource.data = data
	return dataSource
}

export const processSingleValueChart = (
	widget_type_id = 0,
	widget_target_value = 0,
	payload,
	response
) => {
	let widgetTargetValue = Number(widget_target_value)
	let totalValue = 0
	let sipWidgetTargetValue = 0

	let sum = 0
	let count = 0
	let percentageCompletion = 0
	try {
		if (!!response) {
			response.shift()
			for (let iteratorI = 0; iteratorI < response.length; iteratorI++) {
				if (
					[
						18, 50, 51, 55, 56, 57, 58, 62, 64, 131, 132, 133, 134, 135, 136,
						137, 138, 139, 204, 205, 140, 141, 142, 143, 144, 145, 146, 147,
						148, 149, 346, 350, 351, 352, 353, 354, 355, 356, 357, 358, 359,
						547, 548,
					].includes(widget_type_id)
				) {
					// Volumn
					totalValue =
						typeof response[iteratorI].result === 'string'
							? response[iteratorI].result
							: totalValue + response[iteratorI].result
				} else if (
					[19, 44, 50, 51, 55, 56, 57].includes(widget_type_id) ||
					(widget_type_id >= 69 && widget_type_id <= 122)
				) {
					// Value
					sipWidgetTargetValue = response[iteratorI].target
					totalValue =
						typeof response[iteratorI].result === 'string'
							? response[iteratorI].result
							: totalValue + response[iteratorI].result
				} else if (widget_type_id === 20) {
					//TAT
					sum += response[iteratorI].sum
					count += response[iteratorI].count
				}
			}
			if (
				widget_type_id === 18 ||
				widget_type_id === 547 ||
				widget_type_id === 548
			) {
				percentageCompletion = (totalValue / widgetTargetValue) * 100
			} else if (
				widget_type_id === 19 ||
				(widget_type_id >= 69 && widget_type_id <= 122)
			) {
				percentageCompletion = (totalValue / widgetTargetValue) * 100
			} else if (widget_type_id === 20) {
				if (count === 0) {
					totalValue = 0
				} else {
					totalValue = sum / count
					totalValue = getSecondsToDays(totalValue)
				}
			}
		}
		return {
			parsedWidgetData: response,
			sipWidgetTargetValue: sipWidgetTargetValue,
			percentageCompletion: percentageCompletion,
			jsonParams: payload,
			widgetTotalValue: totalValue,
			widgetTargetValue: widgetTargetValue,
		}
	} catch (err) {
		return {
			parsedWidgetData: null,
			stackedTotalValue: totalValue,
			percentageCompletion: percentageCompletion,
		}
	}
}

export const processHorizontalBarChartData = (
	widget_type_id,
	payload,
	response
) => {
	let data = []
	const dataSource = {}
	let temp = {}
	let totalValue = 0

	dataSource.chart = {
		bgColor: '#ffffff',
		valueFontColor: '#718096',
		showvalues: '1',
		showPercentValues: '0',
		labelFontColor: '#000',
		theme: 'fusion',
		smartLineColor: '#009B76',
		smartLineThickness: '1',
		labeldisplay: 'ROTATE',
	}

	if (!!response) {
		response.shift()
	}

	let graphValue = 0
	for (let iteratorI = 0; iteratorI < response.length; iteratorI++) {
		response[iteratorI].result.forEach(row => {
			let jsonParams8 = Object.assign({}, payload)
			temp = {}
			totalValue += row.value
			graphValue = row.value

			if (widget_type_id === 25) {
				temp.label = row.activity_status_name
				temp.value = row.value
				jsonParams8.filter_activity_status_id = row.activity_status_id
				temp.linkData = jsonParams8
			} else if (widget_type_id === 26) {
				temp.label = row.activity_status_tag_name
				temp.value = row.value
				jsonParams8.filter_activity_status_tag_id = row.activity_status_tag_id
				temp.linkData = jsonParams8
			} else if (widget_type_id === 27) {
				temp.label = row.activity_status_type_name
				temp.value = row.value
				jsonParams8.filter_activity_status_type_id = row.activity_status_type_id
				temp.linkData = jsonParams8
			}

			data.push(temp)
		})
	}
	dataSource.data = data
	return dataSource
}

export const getValueinBillions = value => {
	value = Number(value)
	let x = value / 1000000000
	x = x.toFixed(2)
	return x + 'B'
}
export const getValueInMillions = value => {
	value = Number(value)
	let x = value / 1000000
	x = x.toFixed(2)
	return x + 'M'
}

export const getNumberValuesInMillions = value => {
	value = Number(value)
	let x = value / 1000000
	x = x.toFixed(2)
	return Number(x)
}

export const getTotalValueFormatted = value => {
	var SI_SYMBOL = ['', 'K', 'M', 'B', 'T', 'Q']
	const tier = Math.floor(Math.log10(Math.abs(value)) / 3)
	let newValue
	let splitValue
	if (tier > 1) {
		splitValue = (value / Math.pow(10, tier * 3)).toString().split('.')

		// slice used to avoid rounding off, which can happen with toFixed
		if (splitValue.length > 1)
			newValue = splitValue[0] + '.' + splitValue[1].slice(0, 3)
		else newValue = splitValue[0]

		newValue += SI_SYMBOL[tier]
	} else {
		splitValue = value.toString().split('.')

		// slice used to avoid rounding off, which can happen with toFixed
		if (splitValue.length > 1)
			newValue = splitValue[0] + '.' + splitValue[1].slice(0, 3)
		else newValue = splitValue[0]
	}
	return newValue
}

export const getFormattedWidgetValue = value => {
	if (isNaN(value)) return 0
	if (Number(value) > 999999999) {
		return getValueinBillions(value)
	} else if (Number(value) > 999999) {
		return getValueInMillions(value)
	}
	// else if (Number(value) > 9999) {
	//   return getValueInThousands(value);
	// }
	else {
		return value
	}
}

export const getInlineFields = (headerList, fieldJson, exportFlag) => {
	const fieldData = fieldJson ? JSON.parse(fieldJson) : fieldJson
	const fieldData_column1_cart =
		!!fieldData && fieldData.column1_cart
			? JSON.parse(fieldData?.column1_cart)
			: fieldData && fieldData.column4_cart
			? JSON.parse(fieldData?.column4_cart)
			: fieldData
	if (!!fieldData) {
		if (headerList.data_type_id === 1) {
			return getCustomFormatttedDate(
				fieldData[headerList.db_field_name],
				headerList.conversion_format
			)
		} else if (headerList.data_type_id === 5) {
			return getValueInMillions(
				fieldData[headerList.db_field_name],
				headerList.conversion_format
			)
		} else {
			if (headerList.flag_is_reference === 2 && !exportFlag) {
				let val = fieldData[headerList.db_field_name]
				return !!val ? (
					<div>
						<CustomToolTip da={fieldData_column1_cart}>
							<Box p='1'>
								<Tag>{fieldData?.[headerList?.db_field_name]}</Tag>
							</Box>
						</CustomToolTip>
					</div>
				) : null
			} else {
				return fieldData[headerList.db_field_name]
			}
		}
	} else {
		return ''
	}
}

export const getInlineRagStatus = (headerList, fieldJson) => {
	const fieldData = fieldJson ? JSON.parse(fieldJson) : fieldJson
	if (!!fieldData && !!headerList.db_field_name.includes('column')) {
		if (!!fieldData[`${headerList.db_field_name}_rag_status_name`]) {
			return fieldData[
				`${headerList.db_field_name}_rag_status_name`
			].toLowerCase()
		}
	} else {
		return ''
	}
}

export const getInlineReferenceFields = (
	headerList,
	fieldJson,
	handleReferenceData
) => {
	const fieldData = !!fieldJson ? JSON.parse(fieldJson) : null

	if (!!fieldData) {
		try {
			let val = fieldData[headerList.db_field_name]
			return !!val ? (
				<div
					onMouseEnter={() =>
						handleReferenceData({
							activity_title: fieldData[headerList.db_field_name],
							activity_id: Number(fieldData[`${headerList.db_field_name}_id`]),
						})
					}
				>
					<CustomToolTip>
						<Box p='1'>
							<Tag>{val}</Tag>
						</Box>
					</CustomToolTip>
				</div>
			) : null
		} catch (err) {
			return ''
		}
	} else {
		return ''
	}
}

const CustomContent = React.forwardRef(({ da, ...rest }, ref) => {
	const {
		state: { toolTipLoader, toolTipActivity },
	} = useDashboardContext()
	const {
		state: { locale },
	} = useLanguageContext()
	const chakraTheme = useTheme()
	return (
		<Portal ref={ref} {...rest}>
			<PopoverContent style={{ width: !!da && '75%' }}>
				<PopoverArrow />
				<PopoverHeader>{toolTipActivity?.activity_title}</PopoverHeader>
				{!da ? (
					<PopoverBody>
						{toolTipLoader ? (
							<Center p={2} my={1}>
								<Spinner
									thickness='4px'
									speed='0.65s'
									emptyColor={chakraTheme.colors.secondary}
									color={chakraTheme.colors.brand[800]}
									size='md'
								/>
							</Center>
						) : (
							<Box p={1}>
								<VStack alignItems={'left'} justifyContent={'flex-start'}>
									<Text>
										<b>Status:</b> {toolTipActivity?.activity_status_name}
									</Text>
									{/* <Text>
									<b>File Type:</b> {toolTipActivity?.tag_type_name}
								</Text> */}
								</VStack>
							</Box>
						)}
					</PopoverBody>
				) : !!da.cart_items ? (
					<PopoverBody>
						<Table>
							<Thead position={'relative'} zIndex={1}>
								<Tr>
									<Th>{locale['Variant Name']}</Th>
									<Th>case</Th>
									<Th>can/bottle</Th>
									<Th>{locale['Quantity']}</Th>
									<Th>{locale['Monthly Price']}</Th>
								</Tr>
							</Thead>
							<Tbody>
								{!!da.cart_items &&
									Array.isArray(da.cart_items) &&
									da.cart_items.map((p, index) => {
										return (
											<Tr key={index}>
												<Td>{p.product_variant_activity_title}</Td>
												<Td>
													<Input
														size='sm'
														width='100px'
														borderRadius='sm'
														type='number'
														min={0}
														value={
															p.product_variant_quantity_1
																? p.product_variant_quantity_1
																: ''
														}
														//onChange={e => onQntyChange(e, p)}
														isDisabled={true}
													/>
												</Td>
												<Td>
													<Input
														size='sm'
														borderRadius='sm'
														width='100px'
														type='number'
														min={0}
														value={
															p.product_variant_quantity_2
																? p.product_variant_quantity_2
																: ''
														}
														//onChange={e => onQntyChange(e, p)}
														isDisabled={true}
													/>
												</Td>
												<Td>
													<Input
														size='sm'
														borderRadius='sm'
														width='100px'
														type='number'
														min={0}
														value={
															p.product_variant_quantity
																? p.product_variant_quantity
																: ''
														}
														//onChange={e => onQntyChange(e, p)}
														isDisabled={true}
													/>
												</Td>
												{p.value === 0 ? (
													<Td>
														<Input
															size='sm'
															borderRadius='sm'
															type='number'
															min={0}
															//value={p.details.activity_cuid_3}
															//onChange={e => onPriceChange(e, p)}
															isDisabled={true}
														/>
													</Td>
												) : (
													<Td>{p.product_variant_unit_price}</Td>
												)}
											</Tr>
										)
									})}
								<Tr>
									<Td>{locale['Total Cart Value']} </Td>
									<Td>
										<Input
											size='sm'
											borderRadius='sm'
											type='number'
											min={0}
											value={
												da.total_double_quantity_lable_1
													? Math.floor(da.total_double_quantity_lable_1)
													: ''
											}
											//onChange={e => onQntyChange(e, p)}
											isDisabled={true}
										/>
									</Td>

									<Td>
										<Input
											size='sm'
											borderRadius='sm'
											type='number'
											min={0}
											value={
												da.total_double_quantity_lable_2
													? Math.floor(da.total_double_quantity_lable_2)
													: ''
											}
											//onChange={e => onQntyChange(e, p)}
											isDisabled={true}
										/>
									</Td>

									<Td>
										<Input
											size='sm'
											borderRadius='sm'
											width='100px'
											type='number'
											min={0}
											value={
												da.cart_total_quantity
													? Math.floor(da.cart_total_quantity)
													: ''
											}
											//onChange={e => onQntyChange(e, p)}
											isDisabled={true}
										/>
									</Td>

									<Td>{Math.floor(da.cart_total_cost)}</Td>
								</Tr>
							</Tbody>
						</Table>
					</PopoverBody>
				) : (
					<PopoverBody>
						<Text>No Data Found</Text>
					</PopoverBody>
				)}
			</PopoverContent>
		</Portal>
	)
})

const CustomToolTip = ({ children, da }) => {
	return (
		<Popover trigger={'hover'}>
			<PopoverTrigger>{children}</PopoverTrigger>
			<CustomContent da={da} />
		</Popover>
	)
}

export const getInlineLeaveFields = (headerList, fieldJson, leaveJson) => {
	const fieldData = JSON.parse(fieldJson)
	if (!!fieldData) {
		try {
			let val = JSON.parse(fieldData[headerList.db_field_name])
			let valArr = Array.isArray(val.leave_data)
				? val.leave_data.filter(Boolean)
				: []
			return (
				<a
					style={{ color: 'blue' }}
					href='#!'
					onClick={() => leaveJson(valArr)}
				>
					{valArr
						.map(l => l.leave_type_name + ' - ' + l.leave_applied + ' days')
						.join(', \n')}
				</a>
			)
		} catch (err) {
			return ''
		}
	} else {
		return ''
	}
}

export const parseDataForExcelExport = (headerList, column, exportFlag) => {
	let payload = {}
	;(headerList || []).forEach(headerItem => {
		payload[headerItem.db_field_name] =
			headerItem.db_field_flag_is_json !== 1
				? headerItem.data_type_id === 1
					? getCustomFormatttedDate(
							column[headerItem.db_field_name],
							headerItem.conversion_format
					  )
					: headerItem.data_type_id === 5
					? getNumberValuesInMillions(
							column[headerItem.db_field_name],
							headerItem.conversion_format
					  )
					: column[headerItem.db_field_name]
				: getInlineFields(
						headerItem,
						column[headerItem.db_field_json],
						exportFlag
				  )
	})
	return payload
}

export const calculateStartEndDate = ({
	selectedYear,
	selectedPeriod,
	selectedPeriodType,
}) => {
	let startDate, endDate, startYear, endYear
	if (!!selectedYear) {
		const financialYear = !!selectedYear && selectedYear.value.split('-')
		startYear = financialYear[0].trim()
		endYear = financialYear[1].trim()
		if (selectedPeriodType.value === 8 || selectedPeriodType.value === 17) {
			startDate = startYear + '-04-01'
			endDate = endYear + '-03-31'
		} else if (selectedPeriodType.value === 14) {
			if (selectedPeriod.value === 1) {
				startDate = startYear + '-04-01'
				endDate = startYear + '-09-30'
			} else {
				startDate = startYear + '-10-01'
				endDate = endYear + '-03-31'
			}
		} else if (selectedPeriodType.value === 7) {
			if (selectedPeriod.value === 1) {
				startDate = startYear + '-04-01'
				endDate = startYear + '-06-30'
			} else if (selectedPeriod.value === 2) {
				startDate = startYear + '-07-01'
				endDate = startYear + '-09-30'
			} else if (selectedPeriod.value === 3) {
				startDate = startYear + '-10-01'
				endDate = startYear + '-12-31'
			} else {
				startDate = endYear + '-01-01'
				endDate = endYear + '-03-31'
			}
		} else if (selectedPeriodType.value === 6) {
			const dateYear = [1, 2, 3].includes(selectedPeriod.value)
				? endYear
				: startYear
			startDate = moment(`${dateYear}-${selectedPeriod.value}`)
				.startOf('month')
				.subtract(2, 'months')
				.format('YYYY-MM-DD')
			endDate = moment(`${dateYear}-${selectedPeriod.value}`)
				.endOf('month')
				.format('YYYY-MM-DD')
		}
	}
	return { startDate, endDate, startYear, endYear }
}

export const doesHaveFullAccess = (assetId, doesHaveAccess) => {
	const stagingAssetWhiteList = [34810]
	const preprodAssetWhiteList = [31991]
	const prodAssetWhiteList = [31991]
	switch (process.env.REACT_APP_ENV) {
		case 'staging':
			return stagingAssetWhiteList.includes(assetId)
		case 'grene-staging':
			return stagingAssetWhiteList.includes(assetId)
		case 'preprod':
			return preprodAssetWhiteList.includes(assetId)
		case 'grene-preprod':
			return preprodAssetWhiteList.includes(assetId)
		case 'prod':
			return prodAssetWhiteList.includes(assetId)
		case 'grene-prod':
			return prodAssetWhiteList.includes(assetId)
		default:
			return doesHaveAccess
	}
}

export const processStatusTableData = (payload, response) => {
	let data = []
	let temp = {}

	if (!!response) {
		response.shift()
	}
	for (let iteratorI = 0; iteratorI < response.length; iteratorI++) {
		response[iteratorI].result.map(row => {
			let jsonParams5 = Object.assign({}, payload)
			temp = {}
			let statuses = []
			if (row.hasOwnProperty('activity_status_tag_name')) {
				temp.label = row.activity_status_tag_name
				temp.value = row.value || 0
				let filter_status = {
					activity_status_tag_id: row.activity_status_tag_id,
				}
				statuses.push(filter_status)
				jsonParams5.filter_activity_status_tag_id = row.activity_status_tag_id
				temp.link = jsonParams5
			} else {
				temp.label = row.activity_status_name
				temp.value = row.value
				let filter_status = {
					activity_status_id: row.activity_status_id,
				}
				statuses.push(filter_status)
				jsonParams5.filter_activity_status_id = row.activity_status_id
				jsonParams5.activity_status_id =
					'[{"activity_status_id":' + row.activity_status_id + '}]'
				temp.link = jsonParams5
			}
			data.push(temp)
		})
	}
	return {
		jsonParams: payload,
		parsedWidgetData: data,
	}
}
